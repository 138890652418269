import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Card, Dropdown, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/avatar-rounded.webp';
import FeatherIcon from 'feather-icons-react';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import useAuthHook from '../../../hooks/useAuthHook';
import Button from '../../base/Button';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../helpers/utils';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { user } = useAuthHook();
  const [navItems] = useState([
    {
      label: `${t('common.label.profile')}`,
      icon: 'user',
      path: '/profile'
    },
    {
      label: `${t('common.label.dashboard')}`,
      icon: 'pie-chart',
      path: '/'
    }
  ]);
  const { logout } = useAuthHook();
  const handleSignOut = async () => {
    try {
      await logout();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={avatar} size="xl" />
            <h5 className="text-body-emphasis">{user?.name}</h5>
            <h6>({user?.email})</h6>
          </div>
          <div style={{ height: '6.5rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href={item.path} className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="border-top border-translucent">
          <div className="px-3">
            <Button
              onClick={handleSignOut}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              {capitalize(t(`common.action.sign_out`))}
            </Button>
          </div>
          {/* <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!">
              Privacy policy
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#!">
              Terms
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#!">
              Cookies
            </Link>
          </div> */}
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
