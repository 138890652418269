import GeneralSettingsService from 'services/settings/GeneralSettingsService';
import { useDispatch, useSelector } from 'store';
import {
  setTenantProfile,
  setUserProfile
} from 'store/reducers/settings/generalSettingsSlice';
import { TenantProfileType, UserProfileType } from 'types/settings';

const useGeneralSettings = () => {
  const dispatch = useDispatch();
  const { tenantProfile, userProfile } = useSelector(
    state => state.generalSettings
  );

  const fetchTenantProfile = async (): Promise<TenantProfileType> => {
    try {
      const resp = await GeneralSettingsService.fetchTenantProfile();
      const row = resp?.data?.data || null;
      dispatch(setTenantProfile({ row }));
      return row;
    } catch (e) {
      console.log('fetchTenantProfile', e);
      throw e;
    }
  };

  const updateTenantProfile = async (data: FormData) => {
    try {
      const resp = await GeneralSettingsService.updateTenantProfile(data);
      const row = resp?.data?.data || null;
      dispatch(setTenantProfile({ row }));
    } catch (e) {
      console.log('updateTenantProfile', e);
      throw e;
    }
  };

  const fetchUserProfile = async (): Promise<UserProfileType> => {
    try {
      const resp = await GeneralSettingsService.fetchUserProfile();
      const row = resp?.data?.data || null;
      dispatch(setUserProfile({ row }));
      return row;
    } catch (e) {
      console.log('fetchUserProfile', e);
      throw e;
    }
  };

  const updateUserProfile = async (data: FormData) => {
    try {
      const resp = await GeneralSettingsService.updateUserProfile(data);
      const row = resp?.data?.data || null;
      dispatch(setUserProfile({ row }));
    } catch (e) {
      console.log('updateUserProfile', e);
      throw e;
    }
  };

  return {
    tenantProfile,
    userProfile,
    fetchTenantProfile,
    updateTenantProfile,
    fetchUserProfile,
    updateUserProfile
  };
};

export default useGeneralSettings;
