import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { UserProfileType } from 'types/settings';
import { ChangeEvent, useEffect, useState } from 'react';
import Button from 'components/base/Button';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultUserImage from 'assets/img/team/avatar-rounded.webp';
import useGeneralSettings from 'hooks/settings/useGeneralSettings';
import { toast } from 'react-toastify';

const CompanyInformation = () => {
  const { t }: { t: TFunction } = useTranslation();
  const page = t('common.label.my_profile');

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const { fetchUserProfile, updateUserProfile } = useGeneralSettings();

  const [initialValues, setInitialValues] = useState<UserProfileType>({
    image: null,
    email: '',
    name: '',
    phone: ''
  });

  useEffect(() => {
    fetchUserProfile()
      .then(response => {
        const { image, email, name } = response;

        setInitialValues({
          image,
          email,
          name
        });
        const imageUrl = image
          ? `${process.env.REACT_APP_ASSET_ENDPOINT}/${image}`
          : null;
        setSelectedImage(imageUrl as string);
      })
      .catch(error => {
        console.log('Error fetching tenant profile details:', error);
      });
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t('component.form.validation.mandatory', {
        field: t('common.label.company_name').toLowerCase()
      })
    ),
    phone: Yup.string().required(
      t('component.form.validation.mandatory', {
        field: t('common.label.phone').toLowerCase()
      })
    )
  });

  const handleUploadClick = () => {
    const fileInput = document.getElementById('image');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList && fileList[0]) {
      const preview = URL.createObjectURL(fileList[0]);
      setSelectedImage(preview);
    }
  };

  const handleDeleteImage = () => {
    setSelectedImage(null);
  };

  const handleOnSubmit = async (values: UserProfileType) => {
    const formData = new FormData();

    if (values.image instanceof File && selectedImage) {
      formData.append('image', values.image);
    }

    if (values.phone) {
      formData.append('phone', values.phone);
    }

    if (values.name) {
      formData.append('name', values.name);
    }

    if (!selectedImage) {
      formData.append('delete_image', '1');
    } else {
      formData.append('delete_image', '0');
    }

    await updateUserProfile(formData)
      .then(() => {
        toast.success(t('common.message.success.update', { page, name: null }));
      })
      .catch(() => {
        toast.error(t('common.message.failed'));
      });
  };

  return (
    <Row>
      <Col>
        <h4 className="mb-2">{page}</h4>
      </Col>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col sm={6} xs={12}>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <div className="me-2 avatar avatar-4xl">
                    {selectedImage ? (
                      <img
                        className="rounded-circle"
                        src={selectedImage}
                        alt="selected-image"
                      />
                    ) : (
                      <img
                        className="rounded-circle"
                        src={defaultUserImage}
                        alt="avatar"
                      />
                    )}
                  </div>
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const files = e.target.files;
                      if (files && files.length > 0) {
                        setFieldValue('image', files[0]);
                        handleImageChange(e);
                      }
                    }}
                    style={{ display: 'none' }}
                  />
                  <Button
                    startIcon={
                      <FontAwesomeIcon icon={faUpload} className="me-2" />
                    }
                    variant="light"
                    type="button"
                    className="btn btn-white bg-white"
                    onClick={handleUploadClick}
                  >
                    {t('common.label.upload_new_photo')}
                  </Button>
                  {selectedImage && (
                    <Button
                      type="button"
                      variant="danger"
                      onClick={handleDeleteImage}
                    >
                      {t('common.action.delete')}
                    </Button>
                  )}
                </div>
              </Col>
              <Col sm={4} xs={12}>
                <Form.Group className="mb-3 text-start">
                  <Form.Label htmlFor="name">
                    {t('common.label.name')}
                  </Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    type="text"
                    placeholder={t('common.label.full_name')}
                    className={`form-control form-icon-input ${
                      touched.name && errors.name ? 'is-invalid' : ''
                    }`}
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.name && errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 text-start">
                  <Form.Label htmlFor="phone">
                    {t('common.label.phone')}
                  </Form.Label>
                  <Form.Control
                    id="phone"
                    name="phone"
                    type="number"
                    placeholder={t('common.label.phone')}
                    className={`form-control form-icon-input ${
                      touched.phone && errors.phone ? 'is-invalid' : ''
                    }`}
                    value={values.phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phone && errors.phone && (
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 text-start">
                  <Form.Label htmlFor="email">
                    {t('common.label.email')}
                  </Form.Label>
                  <Form.Control
                    id="email"
                    name="email"
                    type="text"
                    value={values.email}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={10}>
                <div className="d-flex justify-content-end gap-2">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={isSubmitting}
                    className="mb-3"
                  >
                    {t('common.action.save')} {t('common.label.profile')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  );
};

export default CompanyInformation;
