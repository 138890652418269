import Button from 'components/base/Button';
import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import useAuthHook from '../../../hooks/useAuthHook';
import { useNavigate } from 'react-router-dom';
import { AlertType } from '../../../types/alert';
import AlertMessage from 'components/common/AlertMessage';
import { ChangePasswordType } from 'types/auth';

interface ChangeFormPassword {
  password: string;
  new_password: string;
  confirm_password: string;
}

const ChangePassword = () => {
  const navigate = useNavigate();
  const { t }: { t: TFunction } = useTranslation();

  const { changePassword, logout } = useAuthHook();

  const [alert, setAlert] = useState<AlertType | null>(null);
  const initialValues: ChangeFormPassword = {
    password: '',
    new_password: '',
    confirm_password: ''
  };
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);

  const handleSignOut = async () => {
    try {
      await logout();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (countdown !== null) {
      countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown === 1) {
            clearInterval(countdownInterval);
            handleSignOut();
            return null;
          }
          return prevCountdown ? prevCountdown - 1 : null;
        });
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown, navigate]);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.password').toLowerCase()
        })
      )
      .min(
        6,
        t('component.form.validation.min', {
          field: t('common.label.password').toLowerCase(),
          min: '6'
        })
      ),
    new_password: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.password').toLowerCase()
        })
      )
      .min(
        6,
        t('component.form.validation.min', {
          field: t('common.label.password').toLowerCase(),
          min: '6'
        })
      ),
    confirm_password: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.confirm_password').toLowerCase()
        })
      )
      .min(
        6,
        t('component.form.validation.min', {
          field: t('common.label.confirm_password').toLowerCase(),
          min: '6'
        })
      )
      .oneOf(
        [Yup.ref('new_password')],
        t('component.form.validation.match.password')
      )
  });

  const onSubmit = async (values: ChangeFormPassword) => {
    const data: ChangePasswordType = {
      password: values.password,
      new_password: values.new_password
    };
    setSubmitDisabled(true);
    changePassword(data)
      .then(() => {
        setAlert({
          type: 'SUCCESS',
          message: t('auth.changePassword.message.success', { time: 5 })
        });
        setCountdown(5);
        setSubmitDisabled(false);
      })
      .catch(e => {
        setSubmitDisabled(false);
        if (e?.status === 400) {
          setAlert({
            type: 'ERROR',
            message: 'Current password is incorrect.'
          });
        } else {
          setAlert({ type: 'ERROR', message: 'Something went wrong!' });
        }
      });
  };
  return (
    <Container>
      <Row>
        <Col>
          <h4 className="mb-2">{t('page.title.change_password')}</h4>
        </Col>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            values,
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <Form
              className="mt-2 mb-2 text-start"
              noValidate
              onSubmit={handleSubmit}
            >
              <Row>
                <Col xs={12} md={4}>
                  {alert && (
                    <AlertMessage
                      type={alert.type}
                      message={alert.message}
                      timer={countdown}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="password">
                      <Trans t={t}>common.label.current_password</Trans>
                    </Form.Label>
                    <div className="form-icon-container mb-2">
                      <Form.Control
                        id="password"
                        type="password"
                        name="password"
                        className={`form-control form-icon-input ${
                          touched.password && errors.password
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={values.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoComplete="true"
                      />
                      {touched.password && errors.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                      <FontAwesomeIcon
                        icon={faKey}
                        className="text-body fs-9 form-icon"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="new_password">
                      <Trans t={t}>common.label.new_password</Trans>
                    </Form.Label>
                    <div className="form-icon-container mb-2">
                      <Form.Control
                        id="new_password"
                        type="password"
                        name="new_password"
                        className={`form-control form-icon-input ${
                          touched.new_password && errors.new_password
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={values.new_password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoComplete="true"
                      />
                      {touched.new_password && errors.new_password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.new_password}
                        </Form.Control.Feedback>
                      )}
                      <FontAwesomeIcon
                        icon={faKey}
                        className="text-body fs-9 form-icon"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="confirm_password">
                      <Trans t={t}>common.label.confirm_password</Trans>
                    </Form.Label>
                    <div className="form-icon-container mb-4">
                      <Form.Control
                        id="confirm_password"
                        type="password"
                        name="confirm_password"
                        className={`form-control form-icon-input ${
                          touched.confirm_password && errors.confirm_password
                            ? 'is-invalid'
                            : ''
                        }`}
                        value={values.confirm_password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoComplete="true"
                      />
                      {touched.confirm_password && errors.confirm_password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.confirm_password}
                        </Form.Control.Feedback>
                      )}
                      <FontAwesomeIcon
                        icon={faKey}
                        className="text-body fs-9 form-icon"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4}>
                  <div className="d-flex justify-content-end gap-2">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting || isSubmitDisabled}
                    >
                      <Trans t={t}>common.action.save</Trans>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default ChangePassword;
